import type { FC } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import React, {
	Fragment,
	useContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import type { DataProxy } from 'apollo-cache';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { EditorActions } from '@atlaskit/editor-core';
import type { AnnotationInfo } from '@atlaskit/editor-plugins/annotation';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { AnnotationMarkStates, AnnotationTypes } from '@atlaskit/adf-schema';
import type { AddMarkStep, Step } from '@atlaskit/editor-prosemirror/transform';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import { ChromelessEditor } from '@atlaskit/editor-core/appearance-editor-chromeless';

import {
	useInlineCommentsDispatchContext,
	useCommentsContentActions,
} from '@confluence/comment-context';
import { ThirdPartyNudge } from '@confluence/third-party-nudge';
import type {
	InlineCommentReply,
	NewReplyVars,
	ContentRepresentation,
	InlineCommentQueryReply,
	CreateInlineReplyMutationType,
	InlineCommentQueryType,
	InlineCommentQueryVariables,
	GraphQLContentStatus,
	InlineCommentAuthorUser,
	InlineCommentLocation,
} from '@confluence/inline-comments-queries';
import { useCommentSidebarOffset } from '@confluence/inline-comments-hooks';
import { useSessionData } from '@confluence/session-data';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useUnreadCommentsForInlineComment } from '@confluence/unread-comments';
import { ErrorDisplay } from '@confluence/error-boundary';
import { InlineCommentFramework } from '@confluence/inline-comments-common/entry-points/enum';
import {
	getEditorAnnotationEventEmitter,
	getRendererAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import {
	VIEW_INLINE_COMMENT_EXPERIENCE,
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	REPLY_TO_INLINE_COMMENT_EXPERIENCE,
	REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
	EDIT_INLINE_COMMENT_EXPERIENCE,
	DELETE_INLINE_COMMENT_EXPERIENCE,
	createInlineCommentCompoundExperience,
} from '@confluence/experience-tracker';
import { usePageContentId } from '@confluence/page-context';
import { CommentEditor, clearCommentDraft, useInlineCommentQueryParams } from '@confluence/comment';
import {
	CommentDeletionLocation,
	CreateInlineReplyMutation,
	ResolveInlineCommentMutation,
	DeleteInlineCommentMutation,
	DeleteInlineCommentMutationWithStep,
	InlineCommentQuery,
	CommentCreationLocation,
} from '@confluence/inline-comments-queries';
import {
	CollapsedReplies,
	CommentAuthor,
	CommentBody,
	ResolvedCommentNotification,
	ReopenedMessage,
	EditComment,
} from '@confluence/inline-comments-common';
import { fg } from '@confluence/feature-gating';
import { useAddCommentPermissionCheck } from '@confluence/comments-hooks';
import {
	getTargetNodeType,
	handleResolveSuccess,
	handleDeleteSuccess,
	handleMutationFailure,
	handleCreateReplySuccess,
	updateApolloCacheCallback,
	getTranslatedError,
	isAlreadyDeletedError,
	parseError,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import {
	ReplyListContainer,
	NewReplyContainer,
} from '@confluence/inline-comments-common/entry-points/styled';
import type {
	CommentPermissions,
	CommentAction,
	MutationResult,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { constructStepForGql, handleScrollToElement } from '@confluence/comments-util';
import { useInlineCommentsActions } from '@confluence/inline-comments-hooks/entry-points/useInlineComments';
import { END } from '@confluence/navdex';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { getLogger } from '@confluence/logger';
import {
	useShouldDisplayCommentReplyPrompts,
	useSuggestedComments,
} from '@confluence/suggested-comment-prompts';
import { usePageInfo } from '@confluence/page-info';
import {
	CommentActionType,
	useCommentsData,
	UnreadAction,
	CommentType,
	type ReplyData,
} from '@confluence/comments-data';
import { useWindowSize } from '@confluence/dom-helpers/entry-points/useWindowSize';
import { markErrorAsHandled } from '@confluence/graphql';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';
import { withFlags } from '@confluence/flags';
import type { WithFlagsProps } from '@confluence/flags';

import {
	EDITOR_INLINE_COMMENT_RENDER_METRIC,
	RENDERER_INLINE_COMMENT_RENDER_METRIC,
} from './perf.config';
import { MAX_UNCOLLAPSED_REPIES } from './inlineCommentConstants';
import { type CommentNavigationOptions, CommentSidebar } from './CommentSidebar';

const i18n = defineMessages({
	cannotDeleteTitle: {
		id: 'annotation-provider-inline-comments.could.not.delete.title',
		defaultMessage: 'Unable to delete comment',
		description:
			'The title of the flag shown to a user that says their comment could not be deleted',
	},
});

const logger = getLogger('annotation-provider-inline-comment');
type ActionResult = { step: Step; doc: JSONDocNode } | false;

type DeleteOptions = {
	deleteAnnotation: (annotationInfo: AnnotationInfo) => ActionResult;
	onDeleteSuccess: (doc: JSONDocNode, annotationId: string) => void;
};

type InlineCommentProps = {
	editorActions?: EditorActions;
	isEditor?: boolean;
	isArchived?: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
	annotation: AnnotationInfo;
	/**
	 * Return a list of inline node types, which are wrapped by the annotation,
	 * for annotation with given ID.
	 *
	 * The `undefined` will be returned if `editor_inline_comments_on_inline_nodes` is off.
	 *
	 * @todo: Do not forget to remove `undefined` when the
	 *        `editor_inline_comments_on_inline_nodes` is removed.
	 */
	getInlineNodeTypes: (annotationId: string) => string[] | undefined;
	onResolve: (annotationId: string) => void;
	deleteOptions?: DeleteOptions;
	onClose?: () => void;
	onDelete?: (annotationId: string) => void;
	supportedTopLevelActions?: CommentAction[];
	isOpeningMediaCommentFromToolbar?: boolean;
	inlineCommentRef?: (node: any) => void;
	isInlineCommentVisible?: boolean;
};

type ResolvedComment = {
	commentId: string;
	inlineMarkerRef: string;
};

const InlineCommentComponent: FC<InlineCommentProps & WithFlagsProps> = ({
	annotation: selectedAnnotation,
	onResolve,
	deleteOptions,
	onClose,
	isEditor,
	isArchived,
	onNavigationClick,
	onDelete,
	supportedTopLevelActions,
	getInlineNodeTypes,
	isOpeningMediaCommentFromToolbar,
	inlineCommentRef = () => {},
	isInlineCommentVisible = false,
	flags,
}) => {
	const pageMode = useGetPageMode();
	const annotationElementRef = useRef<HTMLElement | null>(null);
	const resolveWindowCloseTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
	const sidebarEl = useRef<HTMLDivElement | null>(null);
	const commentContainerElementRef = useRef<HTMLDivElement | null>(null);
	const [eventEmitter] = useState(() =>
		isEditor ? getEditorAnnotationEventEmitter() : getRendererAnnotationEventEmitter(),
	);
	const [shouldFocusNewReply, setShouldFocusNewReply] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [contentId] = usePageContentId();
	// @ts-ignore FIXME: `contentId` can be `undefined` here, and needs proper handling
	const pageId: string = contentId;
	const { addUnresolvedInlineComment, removeUnresolvedInlineComment } = useInlineCommentsActions();
	const isLivePage = useIsCurrentPageLive();
	const { canAddComments } = useAddCommentPermissionCheck(pageId);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { userId: currentUserId } = useSessionData();
	const {
		focusedCommentId,
		replyToCommentId,
		editCommentId: editCommentQueryId,
	} = useInlineCommentQueryParams();
	const { width: windowWidth } = useWindowSize();

	const [editCommentId, setCommentForEdit] = useState('');
	const [resolvedComment, setResolvedComment] = useState<ResolvedComment | null>(null);
	const [shouldRender, setShouldRender] = useState(false);

	const [isReplyChainCollapsed, setIsReplyChainCollapsed] = useState(true);
	const { onChange, resetContentChanged } = useCommentsContentActions();
	const { setActiveHighlight } = useInlineCommentsDispatchContext();
	const nudgeRef = useRef<HTMLDivElement | null>(null);
	const annotationIdRef = useRef<string>(selectedAnnotation.id);

	const sidebarOffset = useCommentSidebarOffset({
		isEditor: isEditor || false,
		annotationElement: document.getElementById(selectedAnnotation.id),
		isViewCommentMode: true,
		windowWidth,
	});

	const RENDER_METRIC = isEditor
		? EDITOR_INLINE_COMMENT_RENDER_METRIC
		: RENDERER_INLINE_COMMENT_RENDER_METRIC;

	const handleGqlError = (error: Error) => {
		// If the error is reactions based, just set it to null and move on
		if (error.message.search(/reactions/i) !== -1 || error.message.search(/cc-pages/i) !== -1) {
			markErrorAsHandled(error);
		} else {
			experienceTracker.stopOnError({
				name: VIEW_INLINE_COMMENT_EXPERIENCE,
				error,
			});
		}
	};

	const { data, loading, refetch } = useQuery<InlineCommentQueryType, InlineCommentQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		InlineCommentQuery,
		{
			variables: {
				pageId,
				annotationId: selectedAnnotation?.id,
				contentStatus: [isArchived ? 'ARCHIVED' : 'DRAFT', 'CURRENT'] as GraphQLContentStatus[],
			},
			onError: handleGqlError,
			fetchPolicy: 'cache-and-network',
		},
	);

	const { pageInfo, loading: pageInfoLoading } = usePageInfo();

	// When the selectedAnnotation changes make sure we unset whatever resolved logic exists
	useEffect(() => {
		setResolvedComment(null);
		clearTimeout(resolveWindowCloseTimeout.current);
		resolveWindowCloseTimeout.current = undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAnnotation]);

	const [resolveInlineCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ResolveInlineCommentMutation,
	);
	const [deleteInlineCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DeleteInlineCommentMutation,
	);
	const [deleteInlineCommentWithStepFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DeleteInlineCommentMutationWithStep,
	);
	const [createReplyFn] = useMutation<
		{ replyInlineComment: InlineCommentReply },
		{ input: NewReplyVars }
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreateInlineReplyMutation,
	);

	// Editor setup
	const spaceId = pageInfo?.space?.id ?? '';
	const pageType = pageInfo?.type ?? '';

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	// Comment metadata
	const commentData = data?.comments?.nodes?.[0];
	const topCommentUserId = (commentData?.author as InlineCommentAuthorUser)?.accountId;
	const topCommentUserAvatar = commentData?.author?.profilePicture?.path;
	const topCommentDisplayName = commentData?.author?.displayName ?? 'Anonymous';
	const commentId = commentData?.id;
	const createdAtNonLocalized = commentData?.createdAtNonLocalized;
	const when = commentData?.version?.when;
	const isEdited = (commentData?.version?.number || 0) > 1;
	const permissions = commentData?.permissions;
	const content = commentData?.body?.value;
	const permissionType = commentData?.author?.permissionType;
	const numReplies = commentData?.replies?.length || 0;
	const commentThreadLength = 1 + numReplies; // includes root comment
	const resolvedTime = (commentData?.location as InlineCommentLocation)?.inlineResolveProperties
		?.resolvedTime;
	const resolvedUser = (commentData?.location as InlineCommentLocation)?.inlineResolveProperties
		?.resolvedUser;
	const commentUrl = isEditor && !isLivePage ? commentData?.links.editui : commentData?.links.webui;
	const reactionsSummary = commentData?.reactionsSummary ?? null;

	const shouldCollapseReplies =
		isReplyChainCollapsed && numReplies && numReplies > MAX_UNCOLLAPSED_REPIES;

	const [
		{ orderedActiveAnnotationIdList },
		{
			addReplyToCommentThread,
			handleRemovingComments,
			setOrderedActiveAnnotationIdList,
			updateUnreadStatus,
			updateCommentCount,
		},
	] = useCommentsData();

	const handleUpdateUnreadStatus = useCallback(
		(commentIds: Set<string>) => {
			// Update the Comments Panel's data cache that this comment has been read
			updateUnreadStatus(
				{
					inline: {
						[selectedAnnotation.id]: commentIds,
					},
					general: {},
				},
				UnreadAction.READ,
			);
		},
		[updateUnreadStatus, selectedAnnotation.id],
	);

	const { unreadCommentRefs, currentUnreadIds, markCommentReadError } =
		useUnreadCommentsForInlineComment({
			commentData,
			isEditor: Boolean(isEditor),
			focusedCommentId: focusedCommentId ?? null,
			shouldCollapseReplies: Boolean(shouldCollapseReplies),
			setIsReplyChainCollapsed,
			isInlineCommentVisible,
			onUpdateUnreadStatus: handleUpdateUnreadStatus,
		});

	const [, { updateSuggestedCommentIDMapLength }] = useSuggestedComments();
	const shouldDisplayCommentReplyPrompts = useShouldDisplayCommentReplyPrompts({
		contentId: pageId,
		commentData,
		userId: currentUserId,
	});

	useEffect(() => {
		RENDER_METRIC.start();

		experienceTracker.start({
			name: VIEW_INLINE_COMMENT_EXPERIENCE,
			id: selectedAnnotation.id,
			attributes: {
				mode: pageMode,
				framework: InlineCommentFramework.ANNOTATION_PROVIDER,
			},
		});
	}, [RENDER_METRIC, selectedAnnotation, experienceTracker, isEditor, pageMode]);

	useEffect(() => {
		if (commentId) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'viewed',
					actionSubject: 'inlineCommentsSidebar',
					objectType: 'page',
					objectId: pageId,
					source: isEditor ? 'editPageScreen' : 'viewPageScreen',
					attributes: {
						mode: pageMode,
						framework: InlineCommentFramework.ANNOTATION_PROVIDER,
						commentsDeckSize: numReplies + 1, // +1 for the parent comment
						commentId,
					},
				},
			}).fire();

			experienceTracker.succeed({
				name: VIEW_INLINE_COMMENT_EXPERIENCE,
			});

			updateSuggestedCommentIDMapLength(commentId, commentThreadLength);
		}
		// We really only need to depend on these two things and want this to fire when ONLY they change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageId, commentId]);

	useEffect(() => {
		if (editCommentQueryId) {
			const isReplyToCommentInChain = commentData?.replies
				?.map((reply) => reply?.id)
				?.includes(editCommentQueryId);
			if (isReplyToCommentInChain) {
				setIsReplyChainCollapsed(false);
			}
			setCommentForEdit(editCommentQueryId);
		}
	}, [editCommentQueryId, commentData]);

	useEffect(() => {
		const isReplyToCommentInChain = commentData?.replies
			?.map((reply) => reply?.id)
			?.includes(replyToCommentId);
		setShouldFocusNewReply(
			Boolean(replyToCommentId) && (isReplyToCommentInChain || replyToCommentId === commentId),
		);
	}, [replyToCommentId, commentId, commentData]);

	useEffect(() => {
		if (focusedCommentId) {
			const isReplyToCommentInChain = commentData?.replies
				?.map((reply) => reply?.id)
				?.includes(focusedCommentId);
			if (isReplyToCommentInChain) {
				setIsReplyChainCollapsed(false);
			}
		}
	}, [focusedCommentId, commentData]);

	useEffect(() => {
		const resolved = (commentData?.location as InlineCommentLocation)?.inlineResolveProperties
			?.resolved;

		if (resolved && !isEditor) {
			const commentId = commentData?.id;
			const markerRef = (commentData?.location as InlineCommentLocation)?.inlineMarkerRef;

			if (commentId && markerRef) {
				setResolvedComment({
					commentId,
					inlineMarkerRef: markerRef,
				});

				eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
					[markerRef]: {
						id: markerRef,
						annotationType: AnnotationTypes.INLINE_COMMENT,
						state: AnnotationMarkStates.RESOLVED,
					},
				});
			}
		}
	}, [commentData, eventEmitter, isEditor]);

	useEffect(() => {
		if (loading === false && pageInfoLoading === false) {
			setShouldRender(true);
		}
	}, [loading, pageInfoLoading]);

	useEffect(() => {
		return () => {
			setShouldRender(false);
		};
	}, [commentId, pageId, selectedAnnotation]);

	useEffect(() => {
		// if the user has been shown the resolved comment view, but navigated away to another comment
		// we need to cancel the autodismiss functionality, as it will close this entire view and not just the
		// resolved comment notification
		if (commentId !== resolvedComment?.commentId) {
			clearTimeout(resolveWindowCloseTimeout.current);
			resolveWindowCloseTimeout.current = undefined;
		}
	}, [commentId, resolvedComment, resolveWindowCloseTimeout]);

	const toggleCommentMode = (id = '') => {
		setCommentForEdit(id);
	};

	useEffect(() => {
		const markerRef = (commentData?.location as InlineCommentLocation)?.inlineMarkerRef;
		if (markerRef) {
			setActiveHighlight(markerRef);
		}
	}, [commentData, setActiveHighlight]);

	useEffect(() => {
		return () => {
			setActiveHighlight(null);
		};
	}, [setActiveHighlight]);

	// Not used
	const updateApolloCacheForReply = useCallback(
		(actionType: 'delete' | 'create', commentId?: string) =>
			(cache: DataProxy, result: MutationResult) => {
				// If the mutation fails, don't do anything
				if (!result) {
					return;
				}

				const queryVariables = {
					pageId,
					annotationId: selectedAnnotation?.id,
					contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
				};

				try {
					const response = cache.readQuery<InlineCommentQueryType, InlineCommentQueryVariables>({
						query: InlineCommentQuery,
						variables: queryVariables,
					});

					if (!response) {
						return;
					}

					// grab the parent comment
					const parentComment = response?.comments?.nodes?.[0];
					let duplicatedReplies: (InlineCommentQueryReply | null)[] | null = null;

					if (parentComment) {
						if (actionType === 'delete') {
							duplicatedReplies = [...parentComment.replies];

							// Remove the returned commentId from the cache and reset it
							const idxToRemove = duplicatedReplies.findIndex((reply) => reply?.id === commentId);

							if (idxToRemove !== -1) {
								duplicatedReplies.splice(idxToRemove, 1);
							}
						} else {
							const mutationResult = (result.data as CreateInlineReplyMutationType)
								.replyInlineComment;

							// Add the new reply entry to the cache and reset it
							duplicatedReplies = [...parentComment.replies];
							duplicatedReplies.push(mutationResult);
						}

						if (duplicatedReplies) {
							const updatedResponse = {
								...response,
								comments: {
									...response.comments,
									nodes: [
										{
											...parentComment,
											replies: duplicatedReplies,
										},
									],
								},
							};

							cache.writeQuery({
								query: InlineCommentQuery,
								variables: queryVariables,
								data: updatedResponse,
							});
						}
					}
				} catch (err) {
					logger.error`An Error occurred when updating cache for ${actionType} reply - ${err}`;
				}
			},
		[selectedAnnotation, pageId],
	);

	const handleDeleteCommentInRendererUpdated = useCallback(
		(parentId?: string | null, replyCommentId?: string) => {
			const isReply = Boolean(parentId);
			let deleteResult: ActionResult | undefined;
			let mtnVariables: any;

			if (isReply) {
				mtnVariables = {
					variables: {
						commentIdToDelete: replyCommentId,
						deleteFrom: CommentDeletionLocation.LIVE,
					},
				};
			} else {
				try {
					// Call deleteAnnotation(selectedAnnotation) to get step and any other required variables
					deleteResult = deleteOptions?.deleteAnnotation(selectedAnnotation);
				} catch (e) {
					experienceTracker.stopOnError({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
						error: e,
					});
					return;
				}

				if (!deleteResult) {
					experienceTracker.stopOnError({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
						error: new Error(`Unable to remove annotation ${selectedAnnotation.id} from document`),
					});
					return;
				}

				mtnVariables = {
					variables: {
						input: {
							commentId,
							step: constructStepForGql(deleteResult.step as AddMarkStep),
						},
					},
				};
			}

			// After deleting it will not be available, so we need to get the inline node types before deleting.
			const inlineNodeTypes = selectedAnnotation
				? getInlineNodeTypes(selectedAnnotation.id)
				: undefined;

			// top-level comment calls `deleteInlineCommentWithStepFn`
			// reply calls `deleteInlineComment`
			const deleteFn = isReply ? deleteInlineCommentFn : deleteInlineCommentWithStepFn;

			deleteFn({
				...mtnVariables,
				update: isReply
					? updateApolloCacheCallback(
							'delete',
							{
								pageId,
								annotationId: selectedAnnotation.id,
								contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
							},
							parentId,
							replyCommentId,
						)
					: undefined,
			})
				.then(({ data: deleteResultData }) => {
					if (!deleteResultData || (!isReply && !deleteResultData.deleteInlineComment)) {
						throw new Error('No data returned from mutation');
					}

					const deleteAnnotationAndCleanUp = (annotationId: string) => {
						// Update the comment count for deleting the comment by current user
						if (fg('confluence-frontend-comments-panel')) {
							updateCommentCount({
								threadKey: annotationId,
								actionType: CommentActionType.DELETE_COMMENT,
								commentType: CommentType.INLINE,
							});
						}

						if (!isReply) {
							// We handle this being false above and can't get here if it is
							if (deleteResult) {
								deleteOptions?.onDeleteSuccess(deleteResult.doc, annotationId);
							}

							// Reset the content state on successful deletion on a non-reply
							resetContentChanged();
							onClose && onClose();
							handleRemovingComments({
								threadKey: annotationId,
								action: CommentActionType.DELETE_COMMENT,
								commentType: CommentType.INLINE,
							});
						} else if (replyCommentId) {
							handleRemovingComments({
								threadKey: annotationId,
								commentId: replyCommentId,
								action: CommentActionType.DELETE_COMMENT,
								commentType: CommentType.INLINE,
							});
						}
						experienceTracker.succeed({
							name: DELETE_INLINE_COMMENT_EXPERIENCE,
						});
					};

					handleDeleteSuccess({
						commentId,
						annotationId: selectedAnnotation?.id,
						pageId,
						pageMode,
						isReply,
						source: 'viewPageScreen',
						inlineNodeTypes,
						createAnalyticsEvent,
						removeUnresolvedInlineComment,
						onSuccess: deleteAnnotationAndCleanUp,
					});
				})
				.catch((err) => {
					if (fg('confluence_frontend_show_already_deleted_flag')) {
						const parsedError = parseError(err);
						const translatedError = getTranslatedError(parsedError.message, commentId);
						if (isAlreadyDeletedError(translatedError)) {
							void flags.showWarningFlag({
								title: <FormattedMessage {...i18n.cannotDeleteTitle} />,
								description: <FormattedMessage {...translatedError} />,
							});
						}
					}

					void handleMutationFailure({
						experienceTracker,
						experienceName: DELETE_INLINE_COMMENT_EXPERIENCE,
						error: err,
						commentId,
					});
				});
		},
		[
			commentId,
			createAnalyticsEvent,
			deleteOptions,
			experienceTracker,
			resetContentChanged,
			onClose,
			pageId,
			removeUnresolvedInlineComment,
			selectedAnnotation,
			deleteInlineCommentFn,
			deleteInlineCommentWithStepFn,
			pageMode,
			getInlineNodeTypes,
			handleRemovingComments,
			updateCommentCount,
			flags,
		],
	);

	const handleDeleteCommentInRenderer = useCallback(
		(parentId?: string | null, replyCommentId?: string) => {
			const isReply = Boolean(parentId);
			// TODO: refactor so deleteResult isn't called on reply
			// Call onDelete(selectedAnnotation) to get step and any other required variables
			const deleteResult = deleteOptions?.deleteAnnotation(selectedAnnotation);
			if (deleteResult) {
				// top-level comment calls `deleteInlineCommentWithStepFn`
				// reply calls `deleteInlineComment`
				const mtnVariables = {
					variables: isReply
						? {
								commentIdToDelete: replyCommentId,
								deleteFrom: CommentDeletionLocation.LIVE,
							}
						: {
								input: {
									commentId,
									step: constructStepForGql(deleteResult.step as AddMarkStep),
								},
							},
				};

				// After deleting it will not be available, so we need to get the inline node types before deleting.
				const inlineNodeTypes = selectedAnnotation
					? getInlineNodeTypes(selectedAnnotation.id)
					: undefined;

				const deleteFn = isReply ? deleteInlineCommentFn : deleteInlineCommentWithStepFn;

				deleteFn({
					...mtnVariables,
					update: isReply
						? updateApolloCacheCallback(
								'delete',
								{
									pageId,
									annotationId: selectedAnnotation.id,
									contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
								},
								parentId,
								replyCommentId,
							)
						: undefined,
				})
					.then(({ data }) => {
						if (!data || (!isReply && !data.deleteInlineComment)) {
							throw new Error('No data returned from mutation');
						}

						const targetNodeType = getTargetNodeType(selectedAnnotation.id, false);

						if (!isReply) {
							deleteOptions?.onDeleteSuccess(deleteResult.doc, selectedAnnotation?.id);

							removeUnresolvedInlineComment(selectedAnnotation?.id);
						}

						createAnalyticsEvent({
							type: 'sendTrackEvent',
							data: {
								action: 'deleted',
								actionSubject: 'comment',
								actionSubjectId: commentId,
								objectType: 'page',
								objectId: pageId,
								source: 'viewPageScreen',
								attributes: {
									commentType: 'inline',
									mode: pageMode,
									framework: InlineCommentFramework.ANNOTATION_PROVIDER,
									inlineNodeTypes,
									isReply,
									targetNodeType,
								},
							},
						}).fire();

						experienceTracker.succeed({
							name: DELETE_INLINE_COMMENT_EXPERIENCE,
						});

						if (!isReply) {
							// Reset the content state on successful deletion on a non-reply
							resetContentChanged();
							onClose && onClose();
						}
					})
					.catch((err) => {
						void handleMutationFailure({
							experienceTracker,
							experienceName: DELETE_INLINE_COMMENT_EXPERIENCE,
							error: err,
							commentId,
						});
					});
			}
		},
		[
			commentId,
			createAnalyticsEvent,
			deleteInlineCommentFn,
			deleteInlineCommentWithStepFn,
			deleteOptions,
			experienceTracker,
			pageMode,
			onClose,
			pageId,
			removeUnresolvedInlineComment,
			selectedAnnotation,
			getInlineNodeTypes,
			resetContentChanged,
		],
	);

	const handleDeleteCommentInEditorAndLivePagesUpdated = useCallback(
		(parentId?: string | null, replyCommentId?: string) => {
			const isReply = Boolean(parentId);
			const targetNodeType = getTargetNodeType(selectedAnnotation.id, true);
			// both top-level comments and replies call `deleteInlineComment`
			deleteInlineCommentFn({
				variables: {
					commentIdToDelete: isReply ? replyCommentId : commentId,
					deleteFrom: isLivePage ? CommentDeletionLocation.LIVE : CommentDeletionLocation.EDITOR,
				},
				update: isReply
					? updateApolloCacheCallback(
							'delete',
							{
								pageId,
								annotationId: selectedAnnotation.id,
								contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
							},
							parentId,
							replyCommentId,
						)
					: undefined,
			})
				.then(({ data: deleteResultData }) => {
					if (!deleteResultData || (!isReply && !deleteResultData.deleteComment)) {
						throw new Error('No data returned from mutation');
					}

					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'deleted',
							actionSubject: 'comment',
							actionSubjectId: commentId,
							objectType: 'page',
							objectId: pageId,
							source: 'editPageScreen',
							attributes: {
								commentType: 'inline',
								mode: pageMode,
								framework: InlineCommentFramework.ANNOTATION_PROVIDER,
								inlineNodeTypes: selectedAnnotation
									? getInlineNodeTypes(selectedAnnotation.id)
									: undefined,
								isReply,
								targetNodeType,
							},
						},
					}).fire();

					experienceTracker.succeed({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
					});

					if (!isReply) {
						onDelete?.(selectedAnnotation?.id);
						// Remove the comment from the unresolved list
						removeUnresolvedInlineComment(selectedAnnotation?.id);
						// Reset the content state on successful deletion on a non-reply
						resetContentChanged();
						handleRemovingComments({
							threadKey: selectedAnnotation.id,
							action: CommentActionType.DELETE_COMMENT,
							commentType: CommentType.INLINE,
						});
					} else if (replyCommentId) {
						handleRemovingComments({
							threadKey: selectedAnnotation.id,
							commentId: replyCommentId,
							action: CommentActionType.DELETE_COMMENT,
							commentType: CommentType.INLINE,
						});
					}
				})
				.catch((err) => {
					if (fg('confluence_frontend_show_already_deleted_flag')) {
						const parsedError = parseError(err);
						const translatedError = getTranslatedError(parsedError.message, commentId);
						if (isAlreadyDeletedError(translatedError)) {
							void flags.showWarningFlag({
								id: commentId,
								title: <FormattedMessage {...i18n.cannotDeleteTitle} />,
								description: <FormattedMessage {...translatedError} />,
							});
						}
					}

					void handleMutationFailure({
						experienceTracker,
						experienceName: DELETE_INLINE_COMMENT_EXPERIENCE,
						error: err,
						commentId,
					});
				});
		},
		//TODO FIXME: determine the right dep array
		[
			commentId,
			createAnalyticsEvent,
			deleteInlineCommentFn,
			experienceTracker,
			pageMode,
			pageId,
			onDelete,
			selectedAnnotation,
			getInlineNodeTypes,
			resetContentChanged,
			isLivePage,
			removeUnresolvedInlineComment,
			handleRemovingComments,
			flags,
		],
	);

	const handleDeleteCommentInEditorAndLivePages = useCallback(
		(parentId?: string | null, replyCommentId?: string) => {
			const isReply = Boolean(parentId);
			const mtnVariables = {
				commentIdToDelete: commentId,
				deleteFrom: isLivePage ? CommentDeletionLocation.LIVE : CommentDeletionLocation.EDITOR,
			};

			// After deleting it will not be available, so we need to get the inline node types before deleting.
			const inlineNodeTypes = selectedAnnotation
				? getInlineNodeTypes(selectedAnnotation.id)
				: undefined;

			// both top-level comments and replies call `deleteInlineComment`
			deleteInlineCommentFn({
				...mtnVariables,
				update: isReply
					? updateApolloCacheCallback(
							'delete',
							{
								pageId,
								annotationId: selectedAnnotation.id,
								contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
							},
							parentId,
							replyCommentId,
						)
					: undefined,
			})
				.then(({ data }) => {
					if (!data || (!isReply && !data.deleteComment)) {
						throw new Error('No data returned from mutation');
					}

					const deleteAnnotationAndCleanUp = (annotationId: string) => {
						experienceTracker.succeed({
							name: DELETE_INLINE_COMMENT_EXPERIENCE,
						});

						if (!isReply) {
							onDelete?.(annotationId);
							setShouldRender(false);

							// Reset the content state on successful deletion on a non-reply
							resetContentChanged();
						}
					};

					handleDeleteSuccess({
						commentId,
						annotationId: selectedAnnotation?.id,
						pageId,
						pageMode,
						isReply,
						source: 'editPageScreen',
						inlineNodeTypes,
						createAnalyticsEvent,
						removeUnresolvedInlineComment,
						onSuccess: deleteAnnotationAndCleanUp,
					});
				})
				.catch((err) => {
					void handleMutationFailure({
						experienceTracker,
						experienceName: DELETE_INLINE_COMMENT_EXPERIENCE,
						error: err,
					});
				});
		},
		//TODO FIXME: determine the right dep array
		[
			commentId,
			createAnalyticsEvent,
			deleteInlineCommentFn,
			experienceTracker,
			pageId,
			onDelete,
			selectedAnnotation,
			getInlineNodeTypes,
			resetContentChanged,
			isLivePage,
			removeUnresolvedInlineComment,
			pageMode,
		],
	);

	const handleDeleteCommentUpdated = useCallback(
		(parentId?: string | null, replyCommentId?: string) => {
			if (!isEditor) {
				handleDeleteCommentInRendererUpdated(parentId, replyCommentId);
			} else {
				handleDeleteCommentInEditorAndLivePagesUpdated(parentId, replyCommentId);
			}
		},
		[
			isEditor,
			handleDeleteCommentInRendererUpdated,
			handleDeleteCommentInEditorAndLivePagesUpdated,
		],
	);

	const handleDeleteComment = useCallback(
		(parentId?: string | null, replyCommentId?: string) => {
			if (!isEditor) {
				handleDeleteCommentInRenderer(parentId, replyCommentId);
			} else {
				handleDeleteCommentInEditorAndLivePages(parentId, replyCommentId);
			}
		},
		[isEditor, handleDeleteCommentInRenderer, handleDeleteCommentInEditorAndLivePages],
	);

	const handleResolveComment = () => {
		const mtnVariables = {
			variables: { commentId, resolved: true },
		};

		resolveInlineCommentFn(mtnVariables)
			.then(() => {
				const annotationId = selectedAnnotation.id;

				// this will be removed when the prop becomes optional
				onResolve(annotationId);

				// Update the comment count for resolving the comment by current user
				if (fg('confluence-frontend-comments-panel')) {
					const commentCountWithReplies = commentData ? (commentData.replies?.length ?? 0) + 1 : 0;
					updateCommentCount({
						threadKey: annotationId,
						actionType: CommentActionType.RESOLVE_COMMENT_THREAD,
						commentCountWithReplies,
						commentType: CommentType.INLINE,
					});
				}
				handleResolveSuccess({
					commentId,
					parentCommentMarkerRef: annotationId,
					pageId,
					pageMode,
					eventEmitter,
					source: isEditor ? 'editPageScreen' : 'viewPageScreen',
					removeUnresolvedInlineComment,
					createAnalyticsEvent,
					getInlineNodeTypes,
				});

				if (fg('update_annotations_in_inline_comments_on_resolve')) {
					setOrderedActiveAnnotationIdList(
						orderedActiveAnnotationIdList
							.map((status) => status.threadKey)
							.filter((id) => id !== annotationId),
					);
				}

				experienceTracker.succeed({
					name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
				});

				// Show the resolved comment window if not in editor
				if (!isEditor) {
					if (commentId) {
						setResolvedComment({
							commentId,
							inlineMarkerRef: annotationId,
						});
					}

					// If the comment we just resolved is the same as the one we're currently viewing, set a timeout
					// to auto-close the container as it should be showing the resolved comment notification view
					if (resolvedComment?.commentId === commentData?.id) {
						resolveWindowCloseTimeout.current = setTimeout(() => {
							onClose && onClose();
						}, 7500);
					}
				}
			})
			.catch((err) => {
				void handleMutationFailure({
					experienceTracker,
					experienceName: RESOLVE_INLINE_COMMENT_EXPERIENCE,
					error: err,
				});
			});
	};

	const handleCreateReplyUnified = async (adf: object, onSuccess: () => void) => {
		const parentCommentId = commentId ?? '';
		const variables = {
			input: {
				containerId: pageId,
				parentCommentId,
				commentBody: {
					value: JSON.stringify(adf),
					representationFormat: 'ATLAS_DOC_FORMAT' as ContentRepresentation,
				},
				createdFrom: isLivePage
					? CommentCreationLocation.LIVE
					: isEditor
						? CommentCreationLocation.EDITOR
						: CommentCreationLocation.RENDERER,
			},
			pageId,
		};

		const queryVariables = {
			annotationId: selectedAnnotation?.id,
			pageId,
			contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
		};

		return createReplyFn({
			variables,
			update: updateApolloCacheCallback('create', queryVariables, parentCommentId),
		})
			.then(({ data }: any) => {
				const replyInlineComment = data.replyInlineComment;
				const reply: ReplyData = {
					...replyInlineComment,
					isUnread: false,
				};
				addReplyToCommentThread(selectedAnnotation.id, reply, CommentType.INLINE);
				handleCreateReplySuccess({
					onSuccess,
					editCommentQueryId,
					setCommentForEdit,
					data,
					createAnalyticsEvent,
					pageId,
					pageType,
					analyticsSource: AnalyticsSource.STANDALONE,
					commentId,
					pageMode,
					selectedAnnotation,
					getInlineNodeTypes: undefined,
					experienceTracker,
					isEditor: !!isEditor,
				});
			})
			.catch((error: any) => {
				return handleMutationFailure({
					experienceTracker,
					experienceName: REPLY_TO_INLINE_COMMENT_EXPERIENCE,
					error,
					commentId,
					shouldReturnError: true,
				});
			});
	};

	// TODO: Delete handleCreateReply when removing the unified_create_comment_reply_function FG
	const handleCreateReply = async (adf: object, onSuccess: () => void) => {
		let createdFrom;
		if (isLivePage) {
			createdFrom = CommentCreationLocation.LIVE;
		} else {
			createdFrom = isEditor ? CommentCreationLocation.EDITOR : CommentCreationLocation.RENDERER;
		}
		const variables = {
			input: {
				containerId: pageId,
				parentCommentId: commentId ?? '',
				commentBody: {
					value: JSON.stringify(adf),
					representationFormat: 'ATLAS_DOC_FORMAT' as ContentRepresentation,
				},
				createdFrom,
			},
			pageId,
		};

		return createReplyFn({
			variables,
			update: updateApolloCacheForReply('create'), // Not used
		})
			.then(({ data }: any) => {
				// reset the editor
				onSuccess();

				if (editCommentQueryId) {
					setCommentForEdit('');
				}

				const replyCommentInfo = data?.replyInlineComment;

				const markerRef = replyCommentInfo?.location?.inlineMarkerRef;

				const reply: ReplyData = {
					...replyCommentInfo,
					isUnread: false,
				};
				addReplyToCommentThread(markerRef, reply, CommentType.INLINE);

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'created',
						actionSubject: 'comment',
						actionSubjectId: replyCommentInfo?.id, // The newly created comment ID
						objectType: pageType,
						objectId: pageId,
						source: isEditor ? 'editPageScreen' : 'viewPageScreen',
						attributes: {
							commentType: 'inline',
							parentCommentId: commentId ?? null, // analytics event schema type expects string or null
							mode: pageMode,
							framework: InlineCommentFramework.ANNOTATION_PROVIDER,
							navdexPointType: END,
							inlineNodeTypes: selectedAnnotation
								? getInlineNodeTypes(selectedAnnotation.id)
								: undefined,
							targetNodeType: getTargetNodeType(markerRef, isEditor),
						},
					},
				}).fire();

				experienceTracker.succeed({
					name: REPLY_TO_INLINE_COMMENT_EXPERIENCE,
				});
			})
			.catch((err) => {
				return handleMutationFailure({
					experienceTracker,
					experienceName: REPLY_TO_INLINE_COMMENT_EXPERIENCE,
					error: err,
					shouldReturnError: true,
				});
			});
	};

	const updateReactionsCacheFn = useCallback(
		(
			emojiId: string,
			actionType: 'add' | 'delete',
			objectId: string,
			containerId?: string,
			cache?: DataProxy,
		) => {
			const queryVariables = {
				pageId: containerId!,
				annotationId: selectedAnnotation?.id,
				contentStatus: [isArchived ? 'ARCHIVED' : 'DRAFT', 'CURRENT'] as GraphQLContentStatus[],
			};

			try {
				const response: InlineCommentQueryType | null | undefined = cache?.readQuery<
					InlineCommentQueryType,
					InlineCommentQueryVariables
				>({
					query: InlineCommentQuery,
					variables: queryVariables,
				});

				if (!response?.comments?.nodes?.[0]) {
					return;
				}

				const comment = response.comments.nodes[0];
				let cacheReactionsSummary;
				let reply;

				// If the comment matches the comment ID, use it's reactionsSummary
				if (comment.id === objectId) {
					cacheReactionsSummary = { ...comment.reactionsSummary };
				} else {
					// Otherwise, find the reply that matches
					reply = comment.replies.find((r) => r?.id === objectId);
					if (reply) {
						cacheReactionsSummary = { ...reply.reactionsSummary };
					}
				}

				if (!cacheReactionsSummary) {
					return;
				}

				const reactionsAri = cacheReactionsSummary.ari || '';
				const reactionsContainerAri = cacheReactionsSummary.containerAri || '';
				let reactionsCount = cacheReactionsSummary.reactionsCount || 0;
				const updatedReactionsSummary = { ...cacheReactionsSummary };
				const updatedReactionsNodes = [...(cacheReactionsSummary.reactionsSummaryForEmoji || [])];

				if (actionType === 'add') {
					const reaction = updatedReactionsNodes.find((item) => item?.emojiId === emojiId);
					// If reaction exists, update its attributes
					if (reaction && !reaction.reacted) {
						reaction.count++;
						reaction.reacted = true;
					} else {
						// Otherwise, create a new reaction
						const newReaction = {
							emojiId,
							count: 1,
							reacted: true,
							id: `${reactionsContainerAri}|${reactionsAri}|${emojiId}`,
							__typename: 'ReactionsSummaryForEmoji',
						};
						updatedReactionsNodes.push(newReaction);
					}
					++reactionsCount;
				} else if (actionType === 'delete') {
					const reactionIdx = updatedReactionsNodes.findIndex((item) => item?.emojiId === emojiId);
					// If reaction exists, update its attributes
					if (reactionIdx !== -1) {
						const reaction = updatedReactionsNodes[reactionIdx];
						// If we have more than one reaction, remove our user from it and decrement the count
						if (reaction && reaction.count > 1) {
							reaction.count--;
							reaction.reacted = false;
						} else {
							// Otherwise, remove the reaction entirely
							updatedReactionsNodes.splice(reactionIdx, 1);
						}
						--reactionsCount;
					}
				}

				updatedReactionsSummary.reactionsSummaryForEmoji = updatedReactionsNodes;
				updatedReactionsSummary.reactionsCount = reactionsCount ?? 0;

				// Create a deep copy of the comment to ensure Apollo detects the change
				const updatedComment = {
					...comment,
					replies: comment.replies.map((r) => {
						if (r?.id === objectId) {
							return {
								...r,
								reactionsSummary: updatedReactionsSummary,
							};
						}
						return r;
					}),
				};

				// Update the comment's reactionsSummary if it's the target comment
				if (comment.id === objectId) {
					// @ts-ignore reactionsCount will always be defined, so the type comparison is wrong
					updatedComment.reactionsSummary = updatedReactionsSummary;
				}

				const newData = {
					comments: {
						nodes: [updatedComment],
						__typename: 'PaginatedCommentList',
					},
				};

				cache?.writeQuery({
					query: InlineCommentQuery,
					variables: queryVariables,
					data: newData,
				});
			} catch (err) {
				logger.error`An error occurred when updating reactions cache for ${actionType} - ${err}`;
			}
		},
		[selectedAnnotation, isArchived],
	);

	const renderReplies = useCallback(
		(shouldCollapseReplies: boolean) => {
			const replies = data?.comments?.nodes?.[0]?.replies || [];
			const visibleReplies = shouldCollapseReplies ? replies.slice(-1) : replies;

			return visibleReplies.map((reply) => {
				if (!reply) {
					return <></>;
				}
				const {
					id,
					permissions: replyPermissions,
					body,
					reactionsSummary: replyReactionsSummary,
				} = reply;

				const matchingRef = unreadCommentRefs.find((refObj) => refObj.id === id)?.ref;
				if (id === editCommentId) {
					return (
						<EditComment
							key={id}
							commentId={id}
							annotationId={selectedAnnotation.id}
							displayName={reply.author?.displayName ?? 'Anonymous'}
							avatarUrl={reply.author?.profilePicture?.path ?? ''}
							pageId={pageId}
							pageType={pageType}
							spaceId={spaceId}
							content={body?.value}
							displayCommentInViewMode={toggleCommentMode}
							mode={isEditor ? 'edit' : 'view'}
							isReply
							hasMediaUploadPermissions={hasMediaUploadPermissions}
							getInlineNodeTypes={getInlineNodeTypes}
							commentType={CommentType.INLINE}
						/>
					);
				} else {
					return (
						<CommentBody
							key={id}
							userId={(reply.author as InlineCommentAuthorUser)?.accountId ?? ''}
							displayName={reply.author?.displayName}
							avatarUrl={reply.author?.profilePicture?.path}
							date={reply.version?.when ?? ''}
							dateUrl={
								isEditor && !isLivePage
									? reply.links.editui ?? undefined
									: reply.links.webui ?? undefined
							}
							createdAtNonLocalized={reply.createdAtNonLocalized ?? ''}
							versionDate={reply.version?.when ?? ''}
							commentId={id}
							pageId={pageId}
							pageType={pageType}
							isReply
							isEdited={(reply.version?.number || 0) > 1}
							parentCommentId={reply.parentId}
							permissions={replyPermissions}
							editComment={() => toggleCommentMode(id)}
							deleteComment={
								fg('use_updated_delete_functions_for_inline_comments')
									? () => handleDeleteCommentUpdated(reply.parentId, id)
									: () => handleDeleteComment(reply.parentId, id)
							}
							content={reply.body?.value}
							isCommentActive
							mode={isEditor ? 'edit' : 'view'}
							permissionType={reply.author?.permissionType ?? undefined}
							supportedActions={['edit', 'delete', 'resolve']}
							isFocused={focusedCommentId === id}
							isUnread={currentUnreadIds.has(id)}
							onRendered={() => {
								const targetCommentId = focusedCommentId || replyToCommentId;
								if (sidebarOffset > 0 && targetCommentId) {
									handleScrollToElement({
										commentId: id,
										targetCommentId,
										isEditor: Boolean(isEditor),
										useInstantScroll: true,
									});
								}
							}}
							unreadCommentRef={matchingRef}
							commentType={CommentType.INLINE}
							inheritedReactionsData={replyReactionsSummary}
							reactionsCacheUpdateFn={updateReactionsCacheFn}
						/>
					);
				}
			});
		},
		[
			data,
			editCommentId,
			pageType,
			pageId,
			spaceId,
			selectedAnnotation,
			isEditor,
			focusedCommentId,
			replyToCommentId,
			handleDeleteComment,
			handleDeleteCommentUpdated,
			isLivePage,
			sidebarOffset,
			currentUnreadIds,
			unreadCommentRefs,
			hasMediaUploadPermissions,
			getInlineNodeTypes,
			updateReactionsCacheFn,
		],
	);

	const handleOnClose = useCallback(() => {
		const isReply = editCommentId === '';

		experienceTracker.abort({
			name: isReply ? REPLY_TO_INLINE_COMMENT_EXPERIENCE : EDIT_INLINE_COMMENT_EXPERIENCE,
			reason: 'comment discarded by user',
		});

		// Clearing reply draft is handled in the comment editor
		clearCommentDraft(isEditor ? 'edit-inline' : 'inline', isReply ? 'reply' : 'edit');
		setIsReplyChainCollapsed(true);
		setShouldFocusNewReply(false);

		onClose && onClose();
	}, [experienceTracker, editCommentId, isEditor, onClose]);

	const handleReopen = useCallback(
		(annotationId: any) => {
			// Trigger a refetch to get the new details when we reopen the comment
			refetch()
				.then(() => {
					// Emit the event to re-highlight the mark
					eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
						[annotationId]: {
							id: annotationId,
							annotationType: AnnotationTypes.INLINE_COMMENT,
							state: AnnotationMarkStates.ACTIVE,
						},
					});

					addUnresolvedInlineComment(annotationId, pageMode);

					// Show the comment again in the sidebar and clear the timeout
					setResolvedComment(null);
				})
				.catch((error) => {
					logger.error`Unable to refetch the details of the comment; ${error}`;
				});

			clearTimeout(resolveWindowCloseTimeout.current);
			resolveWindowCloseTimeout.current = undefined;
		},
		[eventEmitter, setResolvedComment, addUnresolvedInlineComment, refetch, pageMode],
	);

	const navigationOptions = useMemo(
		() => ({
			// We don't want to show the comment counts for the resolved comment view, and we need to make sure
			// that we're not actually viewing a different comment to the resolved comment
			disableCommentCount:
				Boolean(resolvedComment?.commentId === commentData?.id) ||
				fg('confluence-frontend-comments-panel'),
			commentData: commentData as any,
			onNavigationClick,
		}),
		[resolvedComment, commentData, onNavigationClick],
	);

	useEffect(() => {
		createInlineCommentCompoundExperience.attachCommentExperience.debugPoint(
			'mounted InlineComment',
			{ annotationId: annotationIdRef.current },
		);
		if (fg('confluence_comments_create_comment_experience')) {
			setTimeout(() => {
				// There are multiple apis and effects involved in swapping from the create comment
				// ui to the view comment ui.
				// This works around this by only marking the experience as complete after
				// a short timeout + animation frame.

				requestAnimationFrame(() => {
					if (!annotationElementRef.current) {
						createInlineCommentCompoundExperience.attachCommentExperience.fail(
							new Error('Document missing annotation'),
						);
						return;
					}
					if (!sidebarEl.current) {
						createInlineCommentCompoundExperience.attachCommentExperience.fail(
							new Error('Document missing inline comment ui'),
						);
						return;
					}

					const commentUIVerticalDistanceFromAnnotation = Math.abs(
						annotationElementRef.current.getBoundingClientRect().top -
							sidebarEl.current.getBoundingClientRect?.().top,
					);

					// The 10 pixel allowance has been arbitrarily chosen to allow for some wiggle room
					if (commentUIVerticalDistanceFromAnnotation > 10) {
						createInlineCommentCompoundExperience.attachCommentExperience.fail(
							new Error('View Created Comment UI too far from annotation'),
							{ misalignedBy: commentUIVerticalDistanceFromAnnotation },
						);
						return;
					}

					// Note: This code path will be hit when navigating to an existing comment (vs creating a new one)
					// This should not cause any issues as the experience will either already be marked as complete, or
					// will never have been started.
					// Additional monitoring will be added for non create scenarios in the future.
					createInlineCommentCompoundExperience.attachCommentExperience.complete();
				});
			}, 1000);
		}
	}, []);

	if (!shouldRender) {
		return null;
	}

	const renderComment = () => {
		const matchingRef = unreadCommentRefs.find((refObj) => refObj.id === commentId)?.ref;
		if (commentId === editCommentId) {
			return (
				<EditComment
					pageId={pageId}
					pageType={pageType}
					annotationId={selectedAnnotation.id}
					commentId={commentId}
					spaceId={spaceId}
					content={content ?? ''}
					isReply={false}
					displayCommentInViewMode={toggleCommentMode}
					avatarUrl={topCommentUserAvatar ?? ''}
					displayName={topCommentDisplayName}
					mode={isEditor ? 'edit' : 'view'}
					hasMediaUploadPermissions={hasMediaUploadPermissions}
					getInlineNodeTypes={getInlineNodeTypes}
					commentType={CommentType.INLINE}
				/>
			);
		} else {
			const getDeleteFn = () => {
				if (!isLivePage && isEditor) {
					return undefined;
				}

				if (fg('use_updated_delete_functions_for_inline_comments')) {
					return handleDeleteCommentUpdated;
				}

				return handleDeleteComment;
			};
			return (
				<Fragment>
					<CommentBody
						userId={topCommentUserId}
						avatarUrl={topCommentUserAvatar}
						displayName={topCommentDisplayName}
						date={when ?? ''}
						dateUrl={commentUrl ?? ''}
						createdAtNonLocalized={createdAtNonLocalized ?? ''}
						versionDate={when ?? ''}
						pageId={pageId}
						pageType={pageType}
						commentId={commentId ?? ''}
						isReply={false}
						isEdited={isEdited}
						permissions={permissions as CommentPermissions}
						deleteComment={getDeleteFn()}
						editComment={() => toggleCommentMode(commentId)}
						content={content ?? ''}
						isCommentActive
						mode={isEditor ? 'edit' : 'view'}
						onClose={onClose}
						permissionType={permissionType ?? undefined}
						resolveComment={handleResolveComment}
						supportedActions={supportedTopLevelActions}
						isUnread={currentUnreadIds.has(commentId ?? '')}
						numReplies={numReplies}
						unreadCommentRef={matchingRef}
						commentType={CommentType.INLINE}
						inheritedReactionsData={reactionsSummary}
						reactionsCacheUpdateFn={updateReactionsCacheFn}
					/>
					<ReopenedMessage resolvedUser={resolvedUser ?? undefined} resolvedTime={resolvedTime} />
					<PageSegmentLoadEnd key={`stop-${commentId}`} metric={RENDER_METRIC} />
				</Fragment>
			);
		}
	};

	annotationElementRef.current = document.getElementById(selectedAnnotation.id);

	// Handle case where comment has been deleted
	if (!annotationElementRef.current) {
		return null;
	}

	const isCommentAuthor = topCommentUserId === currentUserId;
	const isFirstComment = commentThreadLength === 1;

	return (
		<CommentSidebar
			nudgeRef={nudgeRef}
			pageId={pageId}
			annotationElement={annotationElementRef.current}
			navigationOptions={navigationOptions as CommentNavigationOptions}
			onClose={isEditor ? handleOnClose : onClose}
			isEditor={isEditor}
			isViewCommentMode
			sidebarOffset={sidebarOffset}
			commentId={commentId}
			scrollIntoView={
				// Scrolling is handled separately for focused comment and unread comments
				// hence we should opt out scroll into view
				!focusedCommentId && !currentUnreadIds.size
			}
			isOpeningMediaCommentFromToolbar={isOpeningMediaCommentFromToolbar}
			sidebarEl={sidebarEl}
			annotationId={selectedAnnotation?.id}
			inlineCommentRef={inlineCommentRef}
		>
			{markCommentReadError && <ErrorDisplay error={markCommentReadError} />}
			{resolvedComment && resolvedComment.commentId === commentData?.id ? (
				<ResolvedCommentNotification
					commentId={resolvedComment.commentId}
					reopenComment={handleReopen}
					pageId={pageId}
					inlineMarkerRef={resolvedComment.inlineMarkerRef}
					mode="view"
				/>
			) : (
				<Fragment>
					{renderComment()}
					{commentData?.replies && commentData?.replies.length > 0 && (
						<ReplyListContainer data-testid="inline-comment-reply-container">
							{shouldCollapseReplies && (
								<CollapsedReplies
									numCollapsedReplies={numReplies - 1}
									onClick={() => setIsReplyChainCollapsed(false)}
								/>
							)}
							{renderReplies(Boolean(shouldCollapseReplies))}
						</ReplyListContainer>
					)}
					{!isArchived && canAddComments && (
						<NewReplyContainer
							mode={isEditor ? 'edit' : 'view'}
							data-cy="editor-reply-container"
							data-testid="inline-comment-new-reply-container"
							ref={commentContainerElementRef}
						>
							<CommentAuthor
								commentMode="reply"
								userId={currentUserId}
								size="small"
								permissionType={permissionType ?? undefined}
							/>
							<CommentEditor
								pageId={pageId}
								pageType={pageType}
								appearance="chromeless"
								EditorComponent={ChromelessEditor}
								onSaveComment={
									fg('unified_create_comment_reply_function')
										? handleCreateReplyUnified
										: handleCreateReply
								}
								onContentChange={onChange}
								onEditorReady={() => {
									experienceTracker.succeed({
										name: REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
									});
								}}
								commentMode="reply"
								commentType={isEditor ? 'edit-inline' : 'inline'}
								spaceId={spaceId}
								showCancelButton
								useNewWarningModal
								hideWatchCheckbox
								expandEditor={shouldFocusNewReply}
								pageMode={isEditor ? 'edit' : 'view'}
								hasMediaUploadPermissions={hasMediaUploadPermissions}
								shouldDisplayCommentReplyPrompts={shouldDisplayCommentReplyPrompts}
								topLevelCommentId={commentId}
								shouldWarnOnInternalNavigation
								commentThreadLength={commentThreadLength}
								commentContainerElementRef={commentContainerElementRef}
							/>
						</NewReplyContainer>
					)}
				</Fragment>
			)}
			{isCommentAuthor && isFirstComment && (
				<ThirdPartyNudge
					trigger="inline-comments"
					showImage={false}
					width={280}
					reference={nudgeRef}
				/>
			)}
		</CommentSidebar>
	);
};

export const InlineComment = withFlags(InlineCommentComponent);
